import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';
import {
  BpkTable,
  BpkTableBody,
} from '@skyscanner/backpack-web/bpk-component-table';

import withErrorBoundary from '../ErrorBoundary';
import FooterBlurb from '../common/FooterBlurb';
import { useMBDContext, buildMBDParam } from '../common/MBD/useMBDContext';

import Snippet from './Snippet';

import type {
  InfoSnippetDecoupledProps,
  SnippetProps,
} from '@skyscanner-internal/falcon-shared-types/types/InfoSnippetDecoupledProps';

import STYLES from './InfoSnippet.module.scss';

const InfoSnippetDecoupled = ({
  disclaimer,
  header: title,
  isMobile = false,
  snippets,
  subheader,
}: InfoSnippetDecoupledProps) => {
  const { rawComponentName, vertical } = useMBDContext();

  if (!snippets?.length) {
    return null;
  }

  return (
    <div
      className={STYLES.InfoSnippet}
      data-tracking-element-id={rawComponentName}
      data-tracking-common-params={buildMBDParam(rawComponentName, vertical)}
    >
      <BpkSectionHeader title={title} description={subheader} />
      <BpkTable className={STYLES.Snippets}>
        <BpkTableBody className={STYLES.Snippets__wrap}>
          {snippets.map((snippet: SnippetProps, index) => (
            <Snippet
              key={snippet.label}
              {...snippet}
              isMobile={isMobile}
              snippetIndex={index}
            />
          ))}
        </BpkTableBody>
      </BpkTable>
      {disclaimer && (
        <div className={STYLES.Disclaimer}>
          <FooterBlurb disclaimerCopy={disclaimer} />
        </div>
      )}
    </div>
  );
};

const InfoSnippetDecoupledWithErrorBoundary = withErrorBoundary(
  InfoSnippetDecoupled,
  'info-snippet-decoupled-component',
);
export default InfoSnippetDecoupledWithErrorBoundary;
